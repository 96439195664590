import './LandingPage.css'

export default () => {
  return (
    <div>
      <div className="background-pattern-container">
        <div className="background-pattern"></div>
      </div>
      <div className="row-1">
        <div className="container">
          <div className="d-flex mobile-flex-column">
            <div className="flex-1 app-description p-3 d-flex justify-center flex-column">
              <h1>A&A Baby Activity Tracker</h1>

              <p>Easiest way to track activities of your babies, especially for twins, triplets and even more.</p>
              <p>
                From twin parents, for all parents!
              </p>
            </div>
            <div className="p-3">
              <img className="app-description-screenshot"
                   src={`${process.env.PUBLIC_URL}/assets/screenshots/screenshot-iphone.png`} alt="screenshot" />
            </div>
          </div>
        </div>
      </div>

      <div className="login-section bg-white">
        <div className="container mt-3 mb-3">
          <div className="d-flex mobile-flex-column">
            <div className="flex-1 login-screenshots-container">
              <img
                className="app-login-screenshot"
                src={`${process.env.PUBLIC_URL}/assets/screenshots/screenshot-apple-login-lr.png`} alt="screenshot"
              />
              <img
                className="app-login-screenshot"
                src={`${process.env.PUBLIC_URL}/assets/screenshots/screenshot-google-login-lr.png`} alt="screenshot"
              />
            </div>

            <div className="m-3 flex-1 d-flex flex-column">
              <h2>
                Easy login with Apple and Google
              </h2>
              <p className="flex-1">Register and sign in with one tap with your Apple ID or Google Account.*</p>
              <p className="login-detail-info"><small><i>*User data collected from Apple and Google are name, surname and email. They're only used
                for identification purposes for registration and sign in. More on <a href="/privacy-policy">Privacy Policy</a> and <a href="/terms-of-use">Terms of Use</a>
              </i></small></p>
            </div>
          </div>
        </div>
      </div>


      {/*<div className="flex-1">

            <div className="card d-flex align-items-center">
              <h2 className="flex-1">Soon on Google Play!</h2>

              <svg className="kOqhQd" aria-hidden="true" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <path fill="none" d="M0,0h40v40H0V0z"></path>
                <g>
                  <path
                    d="M19.7,19.2L4.3,35.3c0,0,0,0,0,0c0.5,1.7,2.1,3,4,3c0.8,0,1.5-0.2,2.1-0.6l0,0l17.4-9.9L19.7,19.2z"
                    fill="#EA4335"></path>
                  <path
                    d="M35.3,16.4L35.3,16.4l-7.5-4.3l-8.4,7.4l8.5,8.3l7.5-4.2c1.3-0.7,2.2-2.1,2.2-3.6C37.5,18.5,36.6,17.1,35.3,16.4z"
                    fill="#FBBC04"></path>
                  <path d="M4.3,4.7C4.2,5,4.2,5.4,4.2,5.8v28.5c0,0.4,0,0.7,0.1,1.1l16-15.7L4.3,4.7z"
                        fill="#4285F4"></path>
                  <path d="M19.8,20l8-7.9L10.5,2.3C9.9,1.9,9.1,1.7,8.3,1.7c-1.9,0-3.6,1.3-4,3c0,0,0,0,0,0L19.8,20z"
                        fill="#34A853"></path>
                </g>
              </svg>
            </div>


            <div className="card d-flex align-items-center">
              <h2 className="flex-1">Soon on Apple Store!</h2>

              <i className="uil uil-apple"></i>
            </div>
          </div>*/}


      <footer className="m-0">
        <a href="/privacy-policy">Privacy Policy</a>
        <a href="/terms-of-use">Terms of Use</a>
      </footer>
    </div>
  )
}
