import './PrivacyPolicy.css';

export default () => {
  return (
    <div className="privacy-policy">
      <header>
        <h1>Privacy Policy</h1>
      </header>

      <main>
        <p><strong>Last Updated:</strong> 1 August 2024</p>

        <p>Thank you for choosing A&A Baby Activity Tracker (“the App”). We are committed to protecting your privacy and
          ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose,
          and safeguard your information when you use our mobile application.</p>

        <h2>1. Information We Collect</h2>

        <p>When you use the App, we may collect the following types of information:</p>

        <h3>1.1 Personal Information:</h3>
        <ul>
          <li><strong>Account Information:</strong> When you create an account, we collect your name, email address, and
            password. These can be obtained from your Apple or Google accounts if you use such services to sign in. These information are
            used only for identification purposes for registration and sign in.
          </li>
          <li><strong>Child Information:</strong> You can add, modify, or delete information about your child, including
            their name, date of birth, and any activities you track (such as feeding times, diaper changes, sleep
            schedules, and other notes).
          </li>
        </ul>

        <h3>1.2 Usage Data:</h3>
        <ul>
          <li>We may collect information about your interactions with the App, including the features you use, the time
            and date of your use, and other diagnostic data.
          </li>
        </ul>

        <h3>1.3 Device Information:</h3>
        <ul>
          <li>We may collect information about the device you use to access the App, including the hardware model,
            operating system, app version, and unique device identifiers.
          </li>
        </ul>

        <h2>2. How We Use Your Information</h2>

        <p>We use the information we collect for the following purposes:</p>

        <h3>2.1 To Provide and Maintain Our Service:</h3>
        <ul>
          <li>To facilitate the creation and management of your account.</li>
          <li>To store and track baby activities as input by you.</li>
        </ul>

        <h3>2.2 To Improve Our Service:</h3>
        <ul>
          <li>To understand and analyze how you use the App.</li>
          <li>To develop new features, products, or services to enhance your experience.</li>
        </ul>

        <h3>2.3 To Communicate with You:</h3>
        <ul>
          <li>To send you updates, notifications, and other information related to the App.</li>
          <li>To respond to your inquiries, support requests, and feedback.</li>
        </ul>

        <h3>2.4 To Protect Our Users:</h3>
        <ul>
          <li>To monitor and prevent any fraudulent or unauthorized activity.</li>
          <li>To enforce our terms and conditions.</li>
        </ul>

        <h2>3. How We Share Your Information</h2>

        <p>We do not share your personal information with third parties except as described in this Privacy Policy:</p>

        <h3>3.1 Service Providers:</h3>
        <ul>
          <li>We may engage third-party companies and individuals to facilitate our services, provide services on our
            behalf, or assist us in analyzing how our App is used. These third parties have access to your personal
            information only to perform these tasks on our behalf and are obligated not to disclose or use it for any
            other purpose.
          </li>
        </ul>

        <h3>3.2 Legal Requirements:</h3>
        <ul>
          <li>We may disclose your information if required to do so by law or in response to valid requests by public
            authorities (e.g., a court or a government agency).
          </li>
        </ul>

        <h3>3.3 Business Transfers:</h3>
        <ul>
          <li>If we are involved in a merger, acquisition, or asset sale, your personal information may be transferred. We
            will provide notice before your personal information is transferred and becomes subject to a different privacy
            policy.
          </li>
        </ul>

        <h2>4. Data Retention</h2>

        <p>We will retain your personal information only for as long as necessary to fulfill the purposes outlined in this
          Privacy Policy unless a longer retention period is required or permitted by law. You may request the deletion of
          your account and all associated data at any time by contacting us.</p>

        <h2>5. Data Security</h2>

        <p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure.
          However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot
          guarantee its absolute security.</p>

        <h2>6. Your Rights and Choices</h2>

        <h3>6.1 Account Information:</h3>
        <ul>
          <li>You can access, update, or delete your account information at any time through the App’s settings.</li>
        </ul>

        <h3>6.2 Child Information:</h3>
        <ul>
          <li>You can add, modify, or delete your child’s information and activity data at any time.</li>
        </ul>

        <h3>6.3 Account Deletion:</h3>
        <ul>
          <li>You may delete your account and all associated data at any time by contacting us. Upon deletion, all data
            associated with your account will be permanently removed from our servers.
          </li>
        </ul>

        <h2>7. Children’s Privacy</h2>

        <p>The App is intended for use by parents or guardians to track the activities of their children. We do not
          knowingly collect personal information from children under 13 without parental consent. If you believe that we
          have collected personal information from a child under 13 without consent, please contact us immediately.</p>

        <h2>8. Changes to This Privacy Policy</h2>

        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
          Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
      </main>

      <footer>
        <p>&copy; 2024 A&A Baby Activity Tracker. All rights reserved.</p>
      </footer>
    </div>
  )
}
