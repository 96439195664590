import './App.css';
import { createBrowserRouter, Link, RouterProvider } from "react-router-dom";
import LandingPage from "./LandingPage/LandingPage";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./TermsOfUse/TermsOfUse";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "terms-of-use",
    element: <TermsOfUse />,
  },
]);

function App() {
  return (
    <>
      <link rel="stylesheet" href="https://unicons.iconscout.com/release/v4.0.8/css/line.css" />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
