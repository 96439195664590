import './TermsOfUse.css';

export default () => {
  return (
    <div className="terms-of-use">
      <header>
        <h1>Terms of Use</h1>
      </header>
      <main>
        <p><strong>Last Updated:</strong> 1 August 2024</p>

        <p>Please read these Terms of Use ("Terms", "Terms of Use") carefully before using A&A Baby Activity Tracker (the "App") operated by Devingen Ltd ("us", "we", or "our"). Your access to and use of the App is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the App.</p>

        <h2>1. Acceptance of Terms</h2>
        <p>By accessing or using the App, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not access the App.</p>

        <h2>2. Use of the App</h2>

        <h3>2.1 Eligibility</h3>
        <p>You must be at least 18 years of age to use the App. By using the App, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into these Terms.</p>

        <h3>2.2 User Account</h3>
        <p>To access certain features of the App, you must create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your account and agree to notify us immediately of any unauthorized use of your account.</p>

        <h3>2.3 Prohibited Activities</h3>
        <p>You agree not to engage in any of the following prohibited activities:</p>
        <ul>
          <li>Using the App for any illegal or unauthorized purpose.</li>
          <li>Interfering with or disrupting the security, integrity, or performance of the App.</li>
          <li>Attempting to gain unauthorized access to the App or its related systems or networks.</li>
          <li>Uploading, transmitting, or distributing any malicious code, viruses, or harmful content.</li>
        </ul>

        <h2>3. Intellectual Property</h2>

        <h3>3.1 Ownership</h3>
        <p>The App and its original content, features, and functionality are and will remain the exclusive property of Devingen Ltd and its licensors. The App is protected by copyright, trademark, and other laws of both the United States and foreign countries.</p>

        <h3>3.2 License</h3>
        <p>Subject to these Terms, we grant you a limited, non-exclusive, non-transferable, non-sublicensable license to use the App for your personal, non-commercial use.</p>

        <h3>3.3 Restrictions</h3>
        <p>You may not:</p>
        <ul>
          <li>Modify, copy, distribute, or create derivative works based on the App.</li>
          <li>Reverse engineer, decompile, or disassemble the App.</li>
          <li>Use the App for any commercial purpose without our prior written consent.</li>
        </ul>

        <h2>4. Termination</h2>

        <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use the App will immediately cease. If you wish to terminate your account, you may simply discontinue using the App.</p>

        <h2>5. Limitation of Liability</h2>

        <p>In no event shall [Your Company Name], nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:</p>
        <ul>
          <li>Your access to or use of or inability to access or use the App.</li>
          <li>Any conduct or content of any third party on the App.</li>
          <li>Any content obtained from the App.</li>
          <li>Unauthorized access, use, or alteration of your transmissions or content.</li>
        </ul>

        <h2>6. Disclaimer</h2>

        <p>The App is provided on an "AS IS" and "AS AVAILABLE" basis. [Your Company Name] makes no representations or warranties of any kind, express or implied, as to the operation of the App or the information, content, materials, or products included on the App.</p>

        <h2>7. Governing Law</h2>

        <p>These Terms shall be governed and construed in accordance with the laws of [Your State/Country], without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.</p>

        <h2>8. Changes</h2>

        <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our App after any revisions become effective, you agree to be bound by the revised terms.</p>

        <h2>9. Contact Us</h2>

        <p>If you have any questions about these Terms, please contact us at [Your Contact Information].</p>
      </main>

      <footer>
        <p>&copy; 2024 A&A Baby Activity Tracker. All rights reserved.</p>
      </footer>
    </div>
  )
}
